<script>
import Multiselect from "vue-multiselect";

import {
} from "@/state/helpers";

export default {
  data() {
    return {
      goals: [
        { _id: 'none',name : this.$t('goals.none')},
        { _id: 'purchase',name : this.$t('goals.purchase')},
        { _id: 'cart',name : this.$t('goals.cart')},
        { _id: 'like',name : this.$t('goals.like')},
        { _id: 'view',name : this.$t('goals.view')},
      ],
      
      status : [
        { id: 'draft',label : this.$t(`broadcasts.status_draft`)},
        { id: 'finished',label : this.$t(`broadcasts.status_finished`)},
        { id: 'running',label : this.$t(`broadcasts.status_running`)},
        { id: 'testing',label : this.$t(`broadcasts.status_testing`)},
      ],

      types: [
        {id: 'email', label: this.$t('broadcasts.channel_email')},
        {id: 'sms', label: this.$t('broadcasts.channel_sms')},
        {id: 'app_push', label: this.$t('broadcasts.channel_app_push')},
        {id: 'web_push', label: this.$t('broadcasts.channel_web_push')},
        {id: 'whatsapp', label: this.$t('broadcasts.channel_whatsapp')},
      ],
      
      auxType: null,
      auxGoal: null,
      auxStatus: null,
      
      filters:[]
    };
  },
  components:{Multiselect},
  props: {
    
  },
  mounted(){
    
  },
  computed: {
    
    
  },
  methods: {
    
    removeAll(){
      this.auxGoal = null;
      this.auxType = null;
      this.auxStatus = null;
    },

    getAllFilters(){
      const filters= [];
      if(this.auxGoal){
        filters.push({
          key: 'goal',
          value: this.auxGoal._id,
          badge: `${this.$t('filter.goal')}: ${this.auxGoal.name}`
        });
      }
      
     if(this.auxType){
        filters.push({
          key: 'type',
          value: this.auxType.id,
          badge: `${this.$t('filter.type')}: ${this.auxType.label}`
        });
      }

      if(this.auxStatus){
        filters.push({
          key: 'status',
          value: this.auxStatus.id,
          badge: `${this.$t('personalizations.status')}: ${this.auxStatus.label}`
        });
      }
        
       
      return filters;
    },

    setAllFilter(filters){
      this.auxGoal = filters.filter(f=> f.key == 'goal').length > 0 ? this.goals.filter(g=> g._id == filters.filter(f=> f.key== 'goal')[0].value)[0]  :null;
      this.auxType = filters.filter(f=> f.key== 'type').length > 0 ? this.types.filter(g=> g.id == filters.filter(f=> f.key== 'type')[0].value)[0]  :null
      this.auxStatus = filters.filter(f=> f.key== 'status').length > 0 ? this.status.filter(g=> g.id == filters.filter(f=> f.key== 'status')[0].value)[0]  :null

      this.filters = filters;
      
    }
  },
  watch:{
    
  }
};
</script>

<template>
  <div class="flex-grow-1 h-100 accordion" role="tablist">
    <div class="mb-2">
      <div class="badge bg-primary m-1" v-if="auxGoal">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('filter.goal')}}: {{auxGoal?.name}}</span>
              <i v-on:click="auxGoal =null" class="fa fa-times interact "></i>
          </div>
      </div>
      <div class="badge bg-primary m-1" v-if="auxType">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('broadcasts.channel')}}: {{auxType?.label}}</span>
              <i v-on:click="auxType =null" class="fa fa-times interact"></i>
          </div>
      </div>
      <div class="badge bg-primary m-1" v-if="auxStatus">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('personalizations.status')}}: {{auxStatus?.label}}</span>
              <i v-on:click="auxStatus =null" class="fa fa-times interact"></i>
          </div>
      </div>
    </div>
    <div class="mb-2">
        <div v-b-toggle="'accordion-0'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
          <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('filter.goal') }}</button>
          <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
        </div>
          <b-collapse :id="`accordion-0`" role="tabpanel" class="mt-2">
            <p class="small mb-1">{{ $t('filter.goal_subtitle') }}</p>
            <multiselect 
              :options="goals"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              track-by="_id" 
              label="name"
              :show-labels="false"
              :multiple="false"
              v-model="auxGoal"
              :allow-empty="false">
            </multiselect>
          </b-collapse>
    </div>
    <div class="mb-2">
      <div v-b-toggle="'accordion-2'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
        <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('broadcasts.channel')}}</button>
        <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
      </div>
        <b-collapse :id="`accordion-2`" role="tabpanel" class="mt-2">
          <p class="small mb-1">{{$t('broadcasts.channel_subtitle')}}</p>
          <multiselect 
            :options="types"
            :selectLabel="$t('common.select')"
            :deselectLabel="$t('common.deselect')"
            track-by="id" 
            label="label"
            :show-labels="false"
            :multiple="false"
            v-model="auxType"
            :allow-empty="false">
          </multiselect>
        </b-collapse>
    </div>
    <div class="mb-2">
      <div v-b-toggle="'accordion-4'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
        <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t(`broadcasts.status`)}}</button>
        <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
      </div>
        <b-collapse :id="`accordion-4`" role="tabpanel" class="mt-2">
          <p class="small mb-1">{{$t('broadcasts.status_subtitle')}}</p>
          <multiselect 
            :options="status"
            :selectLabel="$t('common.select')"
            :deselectLabel="$t('common.deselect')"
            track-by="id" 
            label="label"
            :show-labels="false"
            :multiple="false"
            v-model="auxStatus"
            :allow-empty="false">
          </multiselect>
        </b-collapse>
    </div>
  </div>
</template>
