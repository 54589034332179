<script>

import { required } from "vuelidate/lib/validators";

export default {
  data() {
		return {
      submitted:false,
    }
  },
  validations: {
    broadcast: {
      name: { required },
      description: { required }
    }
  },
  mounted(){
  
 },
 components:{ },
 methods:{
  
  onCreateBroadcastClicked(){
    this.submitted = true;

    this.$v.$touch();
    
    if (this.$v.$invalid) {
      return;
    } 

    this.$emit('createBroadcast', this.broadcast);
  }
  
 },
 emits: ['createBroadcast', 'cancelCreateBroadcast'],
 props:{
  broadcast: Object
 } ,
 watch : {
  broadcast: {
    immediate:true,
    handler(newVal){
      if(!newVal){
        this.broadcast = {}
      }
    }
  }
 } 
  
}

</script>

<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <label>{{$t("broadcasts.name")}}*</label>
        <input
              v-model="broadcast.name"
              type="text"
              class="form-control"
              :placeholder="$t('broadcasts.name_placeholder')"
              :class="{ 'is-invalid': submitted && $v.broadcast.name.$error }"
            />
            <div v-if="submitted && !$v.broadcast.name.required" class="invalid-feedback">
              {{$t('broadcasts.name_required')}}
            </div>
        </div>
    </div>
    <div class="row mb-3">
    <div class="col">
      <label>{{$t("journeys.description")}}*</label>
      <input
            v-model="broadcast.description"
            type="text"
            class="form-control"
            :placeholder="$t('broadcasts.description_placeholder')"
            :class="{ 'is-invalid': submitted && $v.broadcast.description.$error }"
          />
          <div v-if="submitted && !$v.broadcast.description.required" class="invalid-feedback">
              {{$t('broadcasts.name_required')}}
            </div>
      </div>
    </div>
    <div class="text-end pt-5 mt-3">
        <b-button variant="light" @click="$emit('cancelCreateBroadcast')">{{$t('common.cancel')}}</b-button>
        <b-button variant="primary" class="ms-1" @click="onCreateBroadcastClicked">{{$t('common.confirm')}}</b-button>
    </div>
  </div>
</template>
