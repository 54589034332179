<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<script>
import {
  
} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      
    };
  },
  components: { },
  mounted() {
  

  },
  methods: {
    getWinningVariant(data) {
      let idMax = null;
      let maxProbability = -1;

      for (let v in data) {
        let prob = v.conversion_rate
        if (prob > maxProbability) {
            maxProbability = prob;
            idMax = v;
          }
      }

      return maxProbability > 0 ? idMax : null;
    },
    variantWinningName(){
      return this.variants[0].name
    },
    variantWinnerName(winner){
      const variant = this.variants.filter(v=>v._id == winner).length > 0 ?this.variants.filter(v=>v._id == winner)[0] : null;
      if(!variant) return '';

      return variant.name
    },
		winnerSubtitle(winner){
			
      if(!winner){
        return '';	
      }
      const variant = this.variants.filter(v=>v._id == winner).length > 0 ?this.variants.filter(v=>v._id == winner)[0] : null;
      if(!variant) return '';
      
      let text = this.$t('broadcasts.variants_subtitle_winner_conversion_rate');
      text= text.replace('##', `${variant.conversion_rate}%`);

      return text;
    },
    winningSubtitle(){
      
      let text = this.$t('broadcasts.variants_subtitle_winning_conversion_rate');
      text= text.replace('##', `${this.variants[0].conversion_rate}%`);
      
      return text;
    },
    formatDate(date) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      return [day, month, year].join('/');
    }
  },
  props: {
    variants:Array,
    winner:String
  },
	emits:[],
  watch: {
    
  }
}

</script>

<template>
  <div>
    <div class="row">
      <div class="col d-inline-flex align-items-baseline bg-primary bg-gradient p-2 mx-2" v-if="winner">
        <i class="bx bx-trophy font-size-24 me-2 text-white align-self-center"/>
        <div>
          <p class="mb-0 me-2 text-white"> <span class="fw-bold text-white">{{ variantWinnerName(winner) }}</span> {{ winnerSubtitle(winner)}} </p>
        </div>
      </div>
      <div class="col d-inline-flex align-items-baseline bg-success bg-gradient p-2 mx-2" v-else>
        <i class="bx bx-trophy font-size-24 me-2 text-white align-self-center"/>
        <div>
          <p class="mb-0 me-2 text-white"> <span class="fw-bold text-white">{{ variantWinningName() }}</span> {{ winningSubtitle()}}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
      <table class="table table align-end mt-3">
      <thead class="table-light">
        <tr>
          <th style="width:40px"></th>
          <th>{{$t('personalizations.name')}}</th>
          <th>{{'Envíos'}}</th>
          <th>{{$t('personalizations.convertions')}}</th>
          <th>{{$t('personalizations.convertion_rate')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(variant) in variants" :key="variant.name">
            <td style="width:40px">
              <i class="bx bxs-trophy font-size-22 me-1 text-primary" v-if="variant._id == winner"/>
            </td>
            <td class="align-middle" :class="{'fw-bold text-decoration-underline': variant._id == winner}">
              {{ variant.name }}
            </td>
            <td class="align-middle">
              {{ variant.sent }}
            </td>
            <td class="align-middle">
              {{ variant.race }}
            </td>
            <td class="align-middle">
              {{  `${variant.conversion_rate}%` }}
            </td>
          </tr>
      </tbody>
    </table>
    </div>
  </div>
  </div>
</template>
<style>

</style>
